import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContents } from './contentSlice';
import {
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import {
  Add as AddIcon,
  ArticleOutlined,
  QueueMusicOutlined,
} from '@mui/icons-material';
import { history } from '../history/history';
import { Botton } from '../../app/Botton';
import { Header } from '../../app/Header';
import { onClickShiftCtrl } from '../history/onClickShiftCtrl';

export const ContentList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchContents());
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [dispatch]);

  const contents = useSelector((state) => Object.values(state?.content?.byId));

  const renderContents = () => {
    if (contents.length === 0)
      return (
        <ListItem>
          <ListItemText inset>Nenhum conteúdo encontrado</ListItemText>
        </ListItem>
      );

    return contents.map((content) => {
      const font = { fontSize: 'large' };
      const avatar = content.isMusic ? (
        <QueueMusicOutlined {...font} />
      ) : (
        <ArticleOutlined {...font} />
      );
      return (
        <ListItem key={content.id}>
          <ListItemButton
            onClick={() => history.push(`/content/edit/${content.id}`)}
          >
            <ListItemAvatar>{avatar}</ListItemAvatar>
            <ListItemText>{content.title}</ListItemText>
          </ListItemButton>
        </ListItem>
      );
    });
  };

  return (
    <div className="AppMaxWidth">
      <Header title="Conteúdos" />
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {/* {serverErr.message && <ServerError serverErr={serverErr} />} */}
        {renderContents()}
      </List>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: 'fixed',
          bottom: '72px',
          right: '16px',
        }}
        onClick={(event) => {
          onClickShiftCtrl(event, '/content/new');
        }}
      >
        <AddIcon />
      </Fab>
      <Botton />
    </div>
  );
};
