import { AssistantPhoto, AutoStories, PeopleAlt } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import react, { useEffect, useState } from 'react';
import { history } from '../features/history/history';
import { useLocation } from 'react-router-dom';

export const Botton = () => {
  const [value, setValue] = useState();
  const location = useLocation();

  useEffect(() => {
    if (typeof location?.pathname === 'string') {
      const first = location?.pathname?.split('/')[1];
      switch (first) {
        case 'activities':
          setValue(0);
          break;
        case 'content':
          setValue(1);
          break;
        case 'users':
          setValue(2);
          break;
        default:
          break;
      }
    }
  }, [location]);
  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          switch (newValue) {
            case 0:
              history.push('/activities');
              break;
            case 1:
              history.push('/content');
              break;
            case 2:
              history.push('/users');
              break;
            default:
              break;
          }
          setValue(newValue);
        }}
      >
        {/* <BottomNavigationAction label="Atividades" icon={<Timelapse />} /> */}
        <BottomNavigationAction label="Atividades" icon={<AssistantPhoto />} />
        <BottomNavigationAction label="Conteúdos" icon={<AutoStories />} />
        <BottomNavigationAction label="Pessoas" icon={<PeopleAlt />} />
      </BottomNavigation>
    </Paper>
  );
};
