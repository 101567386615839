import { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Header } from '../../app/Header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogin } from '../auth/authSlice';
import { Link, redirect, useSearchParams } from 'react-router-dom';
import { useSchemaResolver } from '../schemas/useSchemaResolver';
import parseErr from '../errors/parse';
import { Botton } from '../../app/Botton';
import { DebugData } from '../errors/DebugData';
import { DebugSwitch } from '../errors/DebugSwitch';
import { history } from '../history/history';

export const Login = () => {
  const variant = 'standard';
  const [debug, setDebug] = useState(false);
  const [searchParams] = useSearchParams();
  const serverErrors = useSelector((state) => state?.auth?.errors);
  const userId = useSelector((state) => state?.auth?.userId);
  const resolver = useSchemaResolver('auth/login-input.schema.json');

  const [redirectPath, setRedirectPath] = useState();
  useEffect(() => {
    setRedirectPath(searchParams.get('redirect'));
  }, [searchParams]);

  const methods = useForm({
    defaultValues: {},
    resolver: resolver,
    mode: 'onBlur',
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  // Redux
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(fetchLogin({ formData: data, redirectPath }));
  };

  return (
    <>
      <div className="AppMaxWidth">
        <FormProvider {...methods}>
          <Header title="Login" />
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2, // Espaçamento entre as linhas
              width: '100%', // Ocupa toda a largura disponível
            }}
          >
            <>
              {userId === null ? (
                <>
                  {serverErrors?.length > 0 && (
                    <Alert severity="error">
                      {parseErr(serverErrors)?.email?.message}
                      {parseErr(serverErrors)?.password?.message}
                    </Alert>
                  )}
                  <Box>
                    <Controller
                      name="email"
                      id="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label="Email"
                          name={field.name}
                          placeholder="Digite seu email"
                          fullWidth
                          variant={variant}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          value={field.value || ''}
                          error={
                            !!errors[field?.name] ||
                            !!parseErr(serverErrors)[field?.name]
                          }
                          helperText={
                            errors[field.name]?.message ||
                            parseErr(serverErrors)[field?.name]?.message
                          }
                        />
                      )}
                    />
                  </Box>

                  <Controller
                    name="password"
                    id="password"
                    control={control}
                    render={({ field }) => {
                      const error = serverErrors?.filter(
                        (error) => error.prop === field.name
                      )[0];
                      return (
                        <TextField
                          label="Senha"
                          name={field.namd}
                          type="password"
                          fullWidth
                          variant={variant}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleSubmit(onSubmit)();
                            }
                          }}
                          // Errors
                          error={
                            !!errors[field?.name] ||
                            !!parseErr(serverErrors)[field?.name]
                          }
                          helperText={
                            errors[field.name]?.message ||
                            parseErr(serverErrors)[field?.name]?.message
                          }
                        />
                      );
                    }}
                  />

                  <Box sx={{ flexGrow: 1 }}>
                    <Typography>
                      <Link to="/auth/password-reset-email">
                        Esqueci a senha
                      </Link>
                    </Typography>
                  </Box>

                  {!isSubmitting && (
                    <Button
                      variant="contained"
                      onClick={() => handleSubmit(onSubmit)()}
                    >
                      Enviar
                    </Button>
                  )}

                  {isSubmitting && (
                    <Button variant="contained" disabled>
                      Enviando...
                    </Button>
                  )}

                  <DebugSwitch debug={debug} setDebug={setDebug} />
                  <DebugData debug={debug} />
                </>
              ) : (
                <>
                  <Alert severity="info">Usuário já logado</Alert>
                  <Botton />
                </>
              )}
            </>
          </Box>
        </FormProvider>
      </div>
    </>
  );
};
