import { FormControlLabel, FormGroup, Switch } from '@mui/material';

export const DebugSwitch = ({ debug = false, setDebug = () => {} }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={debug || false} />}
        label="Debug"
        name="debug"
        onChange={() => setDebug(!debug)}
      />
    </FormGroup>
  );
};
