import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import api from '../../api/studycontrol';
import { history } from '../history/history';
import Cookies from 'js-cookie';

/** THUNKS */

export const requestResetPasswordToken = createAsyncThunk(
  'auth/password-reset/send-email',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post(`/auth/password-reset`).send(formData);
      return response.body;
    } catch (err) {
      const payload = err.response.body;
      payload.status = err.response.status;
      return rejectWithValue(payload);
    }
  }
);

export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.patch(`/auth/password-reset`).send(formData);
      return response.body;
    } catch (error) {
      const payload = error.response.body;
      payload.status = error.response.status;
      return rejectWithValue(payload);
    }
  }
);

export const checkPasswordResetToken = createAsyncThunk(
  'auth/passwordReset/checkToken',
  async (token, { rejectWithValue }) => {
    try {
      const response = await api.get(`/auth/password-reset/${token}`);
      console.log('auth/thunk');
      return true;
    } catch (err) {
      return rejectWithValue(false);
    }
  }
);

export const fetchLogin = createAsyncThunk(
  'auth/fetchLogin',
  async ({ formData, redirectPath }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/auth/login`).send(formData);
      return { data: response.body, redirectPath };
    } catch (error) {
      const payload = error.response.body;
      payload.status = error.response.status;
      return rejectWithValue(payload);
    }
  }
);

/** SLICE */

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userId: null,
    resetEmailSent: false,
    validToken: false,
  },
  reducers: {},
  errors: [],
  reducers: {
    /** ACTIONS */

    setUserId(state, action) {
      // console.log('setUserId acessado', action);
      state.userId = action.payload;
    },
    clearAuth(state) {
      // console.log('clearAuth acessado');
      state.userId = null;
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestResetPasswordToken.fulfilled, (state) => {
        state.resetEmailSent = true;
        state.errors = [];
      })
      .addCase(requestResetPasswordToken.rejected, (state, action) => {
        state.errors = action?.payload?.errors;
      })
      .addCase(fetchLogin.fulfilled, (state, action) => {
        state.userId = action?.payload?.data?.id;
        state.errors = [];

        const redirectPath = action?.payload?.data.redirectPath;
        if (!redirectPath) {
          history.replace('/activities');
        } else {
          history.replace(action?.payload?.data.redirectPath);
        }
      })
      .addCase(fetchLogin.rejected, (state, action) => {
        state.errors = action?.payload?.errors;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.errors = [];
        // history.replace('/auth/login');
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.errors = action?.payload?.errors;
      })
      .addCase(checkPasswordResetToken.fulfilled, (state, action) => {
        state.validToken = action.payload;
      })
      .addCase(checkPasswordResetToken.rejected, (state, action) => {
        state.validToken = action.payload;
      });
  },
});

/** EXPORTS */

export const { extraReducers, setUserId, clearAuth, clearErrors } =
  authSlice.actions;

export default authSlice.reducer;
