import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useFormState } from 'react-hook-form';
import {
  FormControlLabel,
  FormGroup,
  TextField,
  Switch,
  Button,
  Alert,
} from '@mui/material';
import { UserSelect } from '../users/UserSelect';
import { ContentSelect } from '../content/ContentSelect';
import { history } from '../history/history';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { activityInProgress, setShowSuccess } from './activitySlice';
import { StartEnd } from './StartEnd';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { Botton } from '../../app/Botton';
import { fetchUsers } from '../users/userSlice';

export const ActivityForm = ({
  onSubmit,
  methods,
  inEditMode = false,
  activity = {},
}) => {
  const { id } = useParams();
  const variant = 'standard';
  const [debug, setDebug] = useState(false);

  const { handleSubmit, control, watch, reset, setFocus } = methods;

  const { errors, isSubmitting, isSubmitted, isSubmitSuccessful, isDirty } =
    useFormState({ control });

  useEffect(() => {
    reset(undefined, { keepValues: true, keepIsSubmitted: true });
  }, [isSubmitSuccessful]);

  const [showMore, setShowMore] = useState(false);

  const dispatch = useDispatch();

  const handleNewActivity = (activity) => {
    const date = DateTime.fromISO(activity?.startTime?.value);
    const startTime = date?.isValid ? { value: date.toISODate() } : undefined;
    const newActivity = {
      user: activity?.user,
      content: activity?.content,
      startTime,
    };
    dispatch(activityInProgress(newActivity));
    dispatch(setShowSuccess(false));
    history.push(`/activities/new`);
  };

  const showSuccess = useSelector((state) => state.activities?.showSuccess);

  useEffect(() => {
    if (isDirty || !errors) dispatch(setShowSuccess(false));
  }, [isDirty, errors]);

  useEffect(() => {
    // setFocus('')
  }, []);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const renderSuccess = () => (
    <Alert severity="success">Dados enviados com sucesso</Alert>
  );

  const renderErrors = () => {
    return (
      <Alert severity="error">
        Erro ao salvar. Verifique os campos em vermelho abaixo.
      </Alert>
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {showSuccess && renderSuccess()}

        {isSubmitted && Object.keys(errors).length > 0 && renderErrors()}

        <div className="start-end-container ">
          <StartEnd focused={!inEditMode} />
        </div>

        <div className="form-line">
          <ContentSelect />
        </div>

        <div className="form-line">
          <UserSelect />
        </div>

        {(showMore || !!activity?.description) && (
          <div className="form-line">
            <Controller
              name="isClass"
              control={control}
              render={({ field }) => {
                return (
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch checked={field?.value || false} />}
                      label="com professor"
                      name={field.name}
                      onChange={(event) => {
                        field.onChange(event.target.checked);
                      }}
                    />
                  </FormGroup>
                );
              }}
            />
          </div>
        )}

        {(showMore || !!activity?.description) && (
          <div className="form-line">
            <Controller
              name="description"
              id="description"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    label="Descrição"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    name={field.name}
                    value={field.value || ''}
                    inputRef={field.ref}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    fullWidth
                    variant={variant}
                    multiline
                  />
                );
              }}
            />
          </div>
        )}

        {(showMore || !!activity?.progress) && (
          <div className="form-line">
            <Controller
              name="progress"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    name={field.name}
                    label="Progresso"
                    variant={variant}
                    fullWidth
                    multiline
                    onChange={(event) => field.onChange(event.target.value)}
                    value={field.value || ''}
                  />
                );
              }}
            />
          </div>
        )}

        {showMore === true && (
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={debug || false} />}
              label="Debug"
              name="debug"
              onChange={() => setDebug(!debug)}
            />
          </FormGroup>
        )}

        {showMore === false && (
          <div className="show-more" onClick={() => setShowMore(true)}>
            Mais campos
          </div>
        )}

        {!isSubmitting && (
          <Button variant="contained" onClick={() => handleSubmit(onSubmit)()}>
            Salvar
          </Button>
        )}

        {inEditMode && (
          <>
            <Button
              variant="outlined"
              onClick={() => history.push(`/activities/delete/${id}`)}
            >
              Excluir
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                handleNewActivity(activity);
              }}
            >
              Nova atividade
            </Button>
          </>
        )}

        {debug === true && (
          <>
            <pre>{JSON.stringify(watch(), 0, 2)}</pre>
            <pre>Erros:</pre>
            <pre>{JSON.stringify(errors, 0, 2)}</pre>
          </>
        )}

        <Botton />
      </form>
    </FormProvider>
  );
};
