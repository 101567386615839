import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Autocomplete } from '@mui/material';
import { fetchUsers, selectUser } from '../../features/users/userSlice';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

// const getLabelByValue = (options, value) => {
//   return options.filter((option) => option.value === value)[0]?.label;
// };

export const UserSelect = ({ variant = 'standard' }) => {
  const { control, setValue } = useFormContext();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchUsers());
  // }, [dispatch]);
  const users = useSelector((state) => state?.users?.byId);

  const options = Object.entries(users).map(([id, user]) => ({
    label: user.name,
    value: id,
  }));

  const userId = useSelector((state) => state?.users?.uiFilters?.user);
  const user = toObj(userId, options);

  useEffect(() => {
    setValue('user', userId);
  }, [userId, user]);

  const { errors } = useFormState({ control });

  return (
    <div>
      <Controller
        name="user"
        control={control}
        render={({ field }) => {
          // const textValue = getLabelByValue(options, field.value);
          return (
            <Autocomplete
              disablePortal
              autoSelect
              autoHighlight
              options={options}
              value={user || null}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Usuário"
                    variant={variant}
                    error={!!errors[field.name]}
                    helperText={
                      !!errors[field.name]
                        ? 'Deve corresponder a um usuário existente'
                        : ''
                    }
                  />
                );
              }}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(event, selected) => {
                field.onChange(selected?.value);
                dispatch(selectUser(selected?.value));
              }}
            />
          );
        }}
      />
    </div>
  );
};

const toObj = (id, options) => {
  if (!Array.isArray(options)) return;
  const result = options.filter((option) => option.value === id);
  if (!result && result.length === 0) return;
  return result[0];
};
