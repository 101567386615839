import { useEffect } from 'react';
import { fetchSchema } from './schemaSlice';
import { useDispatch, useSelector } from 'react-redux';

import { toNestErrors, validateFieldsNatively } from '@hookform/resolvers';
import Ajv from 'ajv/dist/2020';
import addFormats from 'ajv-formats';
import ajvErrors from 'ajv-errors';
import localize from 'ajv-i18n';
import { appendErrors } from 'react-hook-form';

const parseErrorSchema = (ajvErrors, validateAllFieldCriteria) => {
  // Ajv will return empty instancePath when require error
  ajvErrors.forEach((error) => {
    if (error.keyword === 'required') {
      error.instancePath += '/' + error.params.missingProperty;
    }
  });

  return ajvErrors.reduce((previous, error) => {
    // `/deepObject/data` -> `deepObject.data`
    const path = error.instancePath.substring(1).replace(/\//g, '.');

    if (!previous[path]) {
      previous[path] = {
        message: error.message,
        type: error.keyword,
      };
    }

    if (validateAllFieldCriteria) {
      const types = previous[path].types;
      const messages = types && types[error.keyword];

      previous[path] = appendErrors(
        path,
        validateAllFieldCriteria,
        previous,
        error.keyword,
        messages ? [].concat(messages, error.message || '') : error.message
      );
    }

    return previous;
  }, {});
};

export const ajvResolver =
  (schema, schemaOptions, resolverOptions = {}) =>
  async (values, _, options) => {
    const ajv = new Ajv(
      Object.assign(
        {},
        {
          allErrors: true,
          validateSchema: true,
          strict: false,
          allowUnionTypes: true,
          strictUnionTypes: false,
          verbose: true,
        },
        schemaOptions
      )
    );

    addFormats(ajv);
    ajvErrors(ajv);

    // Quando recebe um array de schemas, o primeiro é o principal e os demais são as referências
    let mainSchema, rest;
    if (Array.isArray(schema)) {
      [mainSchema, ...rest] = schema;
      ajv.addSchema(rest);
    } else {
      mainSchema = schema;
    }

    const validate = ajv.compile(
      Object.assign(
        { $async: resolverOptions && resolverOptions.mode === 'async' },
        mainSchema
      )
    );

    const valid = validate(values);

    options.shouldUseNativeValidation && validateFieldsNatively({}, options);

    if (!valid) {
      localize['pt-BR'](validate.errors);
    }

    return valid
      ? { values, errors: {} }
      : {
          values: {},
          errors: toNestErrors(
            parseErrorSchema(
              validate.errors,
              !options.shouldUseNativeValidation &&
                options.criteriaMode === 'all'
            ),
            options
          ),
        };
  };

export const useSchemaResolver = (schemaName = '') => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSchema(encodeURIComponent(schemaName)));
  }, [dispatch, schemaName]);

  const schema = useSelector((state) => state.schemas[schemaName] || null);

  const resolver = ajvResolver(schema);

  return resolver;
};
