import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api/studycontrol';

export const fetchSchema = createAsyncThunk(
  'schemas/fetchSchema',
  async (id) => {
    const response = await api.get(`/schemas/${id}`);
    return response.body;
  }
);

export const schemaSlice = createSlice({
  name: 'schema',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSchema.fulfilled, (state, action) => {
      const id = decodeURIComponent(action?.meta?.arg);
      return { ...state, [id]: action.payload };
    });
  },
});

export const { extraReducers } = schemaSlice.actions;

export default schemaSlice.reducer;
