import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchUser, deleteUser } from './userSlice';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const UserDelete = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser(id));
  }, [id, dispatch]);

  const handleClose = () => {
    navigate(-1);
  };

  const handleConfirm = (id) => {
    dispatch(deleteUser(id));
  };

  const user = useSelector((state) => state?.users?.byId[id]);

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Apagar pessoa'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você tem certeza que deseja apagar a pessoa {user?.name} ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Não</Button>
        <Button onClick={() => handleConfirm(id)} autoFocus>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};
