import React from 'react';
import { history } from './history/history';
import { Button, FormControl, Typography } from '@mui/material';
import { AssistantPhoto, AutoStories, PeopleAlt } from '@mui/icons-material';
import { Header } from '../app/Header';

export const Home = () => {
  const variant = 'outlined';
  const sxButton = { margin: '10px 0' };
  return (
    <div className="AppMaxWidth contentCenter">
      <Header title="Controle de estudos" />
      {/* <Typography variant="h5" align="center" sx={{ margin: '30px 0 20px 0' }}>
        Controle de estudos
      </Typography> */}
      <FormControl fullWidth sx={{ maxWidth: '300px' }}>
        <Button
          variant={variant}
          sx={sxButton}
          onClick={() => history.push('/activities')}
          startIcon={<AssistantPhoto />}
        >
          Atividades
        </Button>
        <Button
          variant={variant}
          sx={sxButton}
          onClick={() => history.push('/content')}
          startIcon={<AutoStories />}
        >
          Conteúdos
        </Button>
        <Button
          variant={variant}
          sx={sxButton}
          onClick={() => history.push('/users')}
          startIcon={<PeopleAlt />}
        >
          Usuários
        </Button>
      </FormControl>
    </div>
  );
};
