import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField, Button, Alert } from '@mui/material';
import { AdditionalName } from './AdditionalName';
import { UserGender } from './UserGender';
import { history } from '../history/history';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSuccess } from './userSlice';
import { Botton } from '../../app/Botton';

export const UserForm = ({ onSubmit, inEditMode = false }) => {
  const { id } = useParams();
  const variant = 'standard'; // standard, outlined, filled;
  const debug = false;

  const {
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isSubmitted },
    control,
    watch,
  } = useFormContext();

  const onError = (errors) => {
    console.error(errors);
  };

  const showSuccess = useSelector((state) => state?.users?.showSuccess);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isDirty || !errors) dispatch(setShowSuccess(false));
  }, [isDirty, errors]);

  const renderSuccess = () => (
    <Alert severity="success">Dados enviados com sucesso</Alert>
  );

  const renderErrors = () => (
    <Alert severity="error">
      Erro ao salvar. Verifique os campos em vermelho abaixo.
    </Alert>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      {showSuccess && renderSuccess()}
      {isSubmitted && Object.keys(errors).length > 0 && renderErrors()}

      <div className="form-line">
        <Controller
          name="name"
          id="name"
          control={control}
          render={({ field }) => (
            <TextField
              label="Nome do usuário"
              onChange={(event) => {
                field.onChange(event.target.value || undefined);
              }}
              onBlur={field.onBlur}
              name={field.name}
              value={field.value || ''}
              inputRef={field.ref}
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
              variant={variant}
            />
          )}
        />
      </div>

      <div className="form-line">
        <AdditionalName
          variant={variant}
          label="Nomes adicionais"
          name="additionalName"
        />
      </div>

      <div className="form-line">
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              label="Email"
              onChange={(event) => {
                field.onChange(event.target.value || undefined);
              }}
              onBlur={field.onBlur}
              name={field.name}
              value={field.value || ''}
              inputRef={field.ref}
              error={!!errors.email}
              helperText={errors.email?.message}
              fullWidth
              variant={variant}
            />
          )}
        />
      </div>

      <div className="form-line">
        <Controller
          name="birthDate"
          control={control}
          render={({ field }) => (
            <TextField
              label="Data de nascimento"
              onChange={(event) => {
                field.onChange(event.target.value || undefined);
              }}
              onBlur={field.onBlur}
              name={field.name}
              value={field.value || ''}
              inputRef={field.ref}
              error={!!errors.birthDate}
              helperText={!!errors.birthDate && 'no formato aaaa-mm-dd'}
              fullWidth
              variant={variant}
            />
          )}
        />
      </div>

      <div className="form-line">
        <UserGender variant={variant} />
      </div>

      {!isSubmitting && (
        <Button variant="contained" onClick={() => handleSubmit(onSubmit)()}>
          Salvar
        </Button>
      )}

      {inEditMode && (
        <Button
          variant="outlined"
          onClick={() => history.push(`/users/delete/${id}`)}
        >
          Excluir
        </Button>
      )}
      {debug === true && (
        <>
          <pre>{JSON.stringify(watch(), 0, 2)}</pre>
          <pre>Erros:</pre>
          <pre>{JSON.stringify(errors, 0, 2)}</pre>
        </>
      )}
      <Botton />
    </form>
  );
};
