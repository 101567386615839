/** Sincroniza o estado do redux/auth/userId
 * com o estado do cookie access_token
 * num intervalo definido, p.e. a cada 10s
 * -------------------------------------------
 *
 * Por arquitetura do navegador, mesmo que o cookie tenha expirado,
 * o estado dele só é atualizado na próxima interação com o navegador.
 * Caso o navegador fique sem interação e o cookie expire, a aplicação
 * só reconhecerá na próxima interação (um click serve), de onde começa a contar
 * o intervalo de verificação configurado nesta aplicação.
 *
 * Caso não queira ser usada abordagem de verificação a cada intervalo
 * de tempo, mas a cada atualização da store do redux, estudar opção
 * com middleware da store.
 */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { clearAuth, setUserId } from './authSlice';
const { Buffer } = require('buffer');

export const CheckAuth = ({ children }) => {
  const userId = useSelector((state) => state.auth.userId);
  const dispatch = useDispatch();
  const cookieName = 'access_token'; // Substitua pelo nome do seu cookie

  useEffect(() => {
    const checkAuthStatus = () => {
      const cookieValue = Cookies.get(cookieName);
      if (cookieValue) {
        // TODO: ao carregar pela primeira vez a página, userId === null
        // porque o estado do redux não conhece nada, nem havia sido logado.
        // precisa haver alguma forma de ler o jwt token e pegar o id
        // do usuário que consta no token.
        // Como ainda não queremos implantar uma bibliteca JWT no cliente,]
        // já que teriamos que mudar a gestão de chaves de síncronas para
        // assíncronas (chaves públicas e privadas),
        // vamos decodificar o token sem validar a assinatura

        if (!userId) {
          let payload = cookieValue.split('.')[1];
          payload = Buffer.from(payload, 'base64').toString('utf-8');
          payload = JSON.parse(payload);
          dispatch(setUserId(payload.id));
        }
        // other options when cookie exists
        // ...
      } else {
        // console.log("cookie does'n exists");
        if (userId) dispatch(clearAuth());
      }
    };

    // console.log('initializer/useEffect carregado');
    // executar da primeira vez
    checkAuthStatus();

    //Configurar o intervalo para checar a cada 30 segundos
    const intervalId = setInterval(checkAuthStatus, 10000); // in millis

    //Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
  }, [dispatch, userId]);

  return children;
};
