import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { ContentForm } from './ContentForm';
import { editContent, fetchContent } from './contentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSchemaResolver } from '../schemas/useSchemaResolver';
import { Header } from '../../app/Header';

export const ContentEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  //fetch user
  useEffect(() => {
    dispatch(fetchContent(id));
  }, [id, dispatch]);
  const initialValues = useSelector((state) => state?.content?.byId[id] || {});

  const resolver = useSchemaResolver('content.schema.json');
  const methods = useForm({
    defaultValues: initialValues,
    values: initialValues,
    resolver: resolver,
    mode: 'onChange',
  });

  const onSubmit = async (formData) => dispatch(editContent(formData));

  return (
    <div className="AppMaxWidth">
      <FormProvider {...methods}>
        <Header title="Editar conteúdo" showBackIcon={true} />
        <ContentForm onSubmit={onSubmit} inEditMode={true} />
      </FormProvider>
    </div>
  );
};
