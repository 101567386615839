import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from './userSlice';
import {
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Create as CreateIcon,
  Face4TwoTone,
  Face6TwoTone,
} from '@mui/icons-material';
import { history } from '../history/history';
import { Botton } from '../../app/Botton';
import { Header } from '../../app/Header';
import { onClickShiftCtrl } from '../history/onClickShiftCtrl';

export const UserList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchUsers());
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [dispatch]);

  const users = useSelector((state) => Object.values(state?.users?.byId));

  const renderUsers = () => {
    if (users.length === 0)
      return (
        <ListItem>
          <ListItemText inset>Nenhum usuário encontrado</ListItemText>
        </ListItem>
      );

    return users.map((user) => (
      <ListItem key={user.id}>
        <ListItemButton onClick={() => history.push(`/users/edit/${user.id}`)}>
          <ListItemAvatar>
            {user.gender === 'female' ? (
              <Face4TwoTone fontSize="large" />
            ) : (
              <Face6TwoTone fontSize="large" />
            )}
          </ListItemAvatar>
          <ListItemText>{user.name}</ListItemText>
        </ListItemButton>
      </ListItem>
    ));
  };

  return (
    <div className="AppMaxWidth">
      <Header title="Pessoas" />
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {/* {serverErr.message && <ServerError serverErr={serverErr} />} */}
        {renderUsers()}
      </List>

      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: 'fixed',
          bottom: '72px',
          right: '16px',
        }}
        onClick={(event) => {
          onClickShiftCtrl(event, '/users/new');
        }}
      >
        <AddIcon />
      </Fab>
      <Botton />
    </div>
  );
};
