import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Header } from '../../app/Header';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useSchemaResolver } from '../schemas/useSchemaResolver';
import { checkPasswordResetToken, updatePassword } from './authSlice';
import { TextField, Button, Box, Alert, Switch } from '@mui/material';

import parseErr from '../errors/parse';
import { DebugData } from '../errors/DebugData';
import { useEffect, useState } from 'react';
import { DebugSwitch } from '../errors/DebugSwitch';
import { history } from '../history/history';

export const PasswordReset = () => {
  const variant = 'standard';
  const [debug, setDebug] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const resolver = useSchemaResolver('auth/password-reset-input.schema.json');
  const serverErrors = useSelector((state) => state?.auth?.errors);
  const [hasChanged, setHasChanged] = useState(false);

  const methods = useForm({
    defaultValues: {},
    resolver: resolver,
    mode: 'onBlur',
    criteriaMode: 'all',
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = methods;

  const [token, setToken] = useState();

  //in first load
  useEffect(() => {
    dispatch(checkPasswordResetToken(searchParams.get('token')));
  }, []);

  const validToken = useSelector((state) => state?.auth?.validToken);

  useEffect(() => {
    setToken(searchParams.get('token'));
    setValue('token', token);
  }, [searchParams, token]);

  const onSubmit = async (data) => {
    try {
      await dispatch(updatePassword(data));
      setHasChanged(true);
    } catch (err) {}
  };

  const checkPasswords = (password, confirm) => {
    clearErrors('passwordConfirm');
    if (password !== confirm) {
      setError('passwordConfirm', {
        type: 'match',
        message: 'Senhas não coincidem',
      });
    }
  };

  const renderInvalidToken = () => (
    <>
      <Alert severity="error">
        O token é inválido ou expirou. Solicite a restauração de senha
        novamente.
      </Alert>
    </>
  );

  const renderSuccess = () => (
    <>
      <Alert severity="success">Senha alterada com sucesso</Alert>

      <Box>&nbsp;</Box>

      <Button
        variant="contained"
        onClick={() => history.replace('/auth/login')}
      >
        Entrar
      </Button>
    </>
  );

  const renderInputs = () => (
    <>
      {serverErrors?.length && (
        <Alert severity="error">{parseErr(serverErrors)?.token?.message}</Alert>
      )}

      <Controller
        name="token"
        control={control}
        render={({ field }) => <input type="hidden" {...field} />}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            label="Digite a nova senha"
            name={field.name}
            type="password"
            fullWidth
            variant={variant}
            onChange={field.onChange}
            onBlur={() => {
              const confirm = getValues('passwordConfirm');
              if (confirm) checkPasswords(field.value, confirm);
              return field.onBlur;
            }}
            error={
              !!errors[field?.name] || !!parseErr(serverErrors)[field?.name]
            }
            helperText={
              errors[field.name]?.message ||
              parseErr(serverErrors)[field?.name]?.message
            }
          />
        )}
      />

      <Controller
        name="passwordConfirm"
        control={control}
        render={({ field }) => (
          <TextField
            label="Confirme a nova senha"
            name={field.name}
            type="password"
            fullWidth
            variant={variant}
            onChange={field.onChange}
            onBlur={(event) => {
              const password = getValues('password');
              const confirm = getValues('passwordConfirm');

              checkPasswords(password, confirm);
              return field.onBlur;
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                console.log('acessado onKeyDown/Enter');
                handleSubmit(onSubmit)();
              }
            }}
            // ERRORS
            error={
              !!errors[field?.name] || !!parseErr(serverErrors)[field?.name]
            }
            helperText={
              errors[field.name]?.message ||
              parseErr(serverErrors)[field?.name]?.message
            }
          />
        )}
      />

      {!isSubmitting && (
        <Button
          variant="contained"
          onClick={() => {
            if (Object.keys(errors).length === 0)
              return handleSubmit(onSubmit)();
          }}
        >
          Enviar
        </Button>
      )}
      <DebugSwitch debug={debug} setDebug={setDebug} />
      <DebugData debug={debug} />
    </>
  );

  return (
    <div className="AppMaxWidth">
      <Header title="Alteração de senha" showAuthUi={false} />
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2, // Espaçamento entre as linhas
            width: '100%', // Ocupa toda a largura disponível
          }}
        >
          {!validToken && renderInvalidToken()}
          {validToken && !hasChanged && renderInputs()}
          {validToken && hasChanged && renderSuccess()}
        </Box>
      </FormProvider>
    </div>
  );
};
