import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import api from '../../api/studycontrol';
import { history } from '../history/history';

// side effects, only as applicable
// e.g. thunks, epics, etc
export const fetchContents = createAsyncThunk(
  'content/fetchContents',
  async () => {
    const response = await api.get('/content?sort[title]=1');
    return response.body;
  }
);

export const createContent = createAsyncThunk(
  'content/createContent',
  async (formData, { rejectWithValue }) => {
    parseSize(formData.size);
    let response;
    try {
      response = await api.post('/content').send(formData);
      return response.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editContent = createAsyncThunk(
  'content/editContent',
  async (formData, { rejectWithValue }) => {
    parseSize(formData.size);
    try {
      const response = await api.put(`/content/${formData.id}`).send(formData);
      return response.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchContent = createAsyncThunk(
  'content/fetchContent',
  async (id) => {
    const response = await api.get(`/content/${id}`);
    return response.body;
  }
);

export const deleteContent = createAsyncThunk(
  'content/deleteContent',
  async (id) => {
    await api.delete(`/content/${id}`);
    return id;
  }
);

// PARSERS
const parseSize = (size) => {
  if (!size) return;
  for (const key in size) {
    const parsed = parseInt(size[key]);
    size[key] = isNaN(parsed) ? undefined : parsed;
    if (size[key] === undefined) delete size[key];
  }
};

// MAIN FUNCTION
// Slices + Reducers
export const contentSlice = createSlice({
  name: 'content',
  initialState: {
    byId: {},
    showSuccess: false,
    uiFilters: {},
  },
  reducers: {
    setShowSuccess: (state, action) => {
      state.showSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContents.fulfilled, (state, action) => {
        state.byId = _.mapKeys(action.payload, 'id');
      })
      .addCase(createContent.fulfilled, (state, action) => {
        history.push(`/content/edit/${action.payload.id}`);
        state.byId = { ...state.byId, [action.payload.id]: action.payload };
        state.showSuccess = true;
      })
      .addCase(createContent.rejected, (state, action) => {
        // console.log(state, action);
        console.log(action.payload.response.body);
        //TODO: salvar o corpo da rejeição no estado
        return state;
      })
      .addCase(editContent.fulfilled, (state, action) => {
        state.byId = { ...state.byId, [action.payload.id]: action.payload };
        state.showSuccess = true;
      })
      .addCase(editContent.rejected, (state, action) => {
        console.log(action);
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.byId = { ...state.byId, [action.payload.id]: action.payload };
      })
      .addCase(deleteContent.fulfilled, (state, action) => {
        history.push('/content');
        state.byId = _.omit(state.byId, action.payload);
      });
  },
});

export const { extraReducers, setShowSuccess } = contentSlice.actions;

export default contentSlice.reducer;
