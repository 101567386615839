import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

export const DebugData = ({ debug = false }) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const serverErrors = useSelector((state) => state?.auth?.errors);
  return (
    <>
      {debug === true && (
        <>
          <pre>Form Data:</pre>
          <pre>{JSON.stringify(watch(), 0, 2)}</pre>
          <pre>Client Errors:</pre>
          <pre>{JSON.stringify(errors, 0, 2)}</pre>
          <pre>Server Errors:</pre>
          <pre>{JSON.stringify(serverErrors, 0, 2)}</pre>
        </>
      )}
    </>
  );
};
