import { configureStore } from '@reduxjs/toolkit';
import usersReducer from '../features/users/userSlice';
import contentReducer from '../features/content/contentSlice';
import activitiesReducer from '../features/activities/activitySlice';
import schemaReducer from '../features/schemas/schemaSlice';
import authReducer from '../features/auth/authSlice';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    content: contentReducer,
    activities: activitiesReducer,
    schemas: schemaReducer,
    auth: authReducer,
  },
});
