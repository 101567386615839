import React, { useEffect } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import { history } from '../features/history/history';
import { HistoryRouter } from '../features/history/HistoryRouter';

import { Home } from '../features/Home';
import { UserList } from '../features/users/UserList';
import { UserCreate } from '../features/users/UserCreate';
import { UserDelete } from '../features/users/UserDelete';
import { UserEdit } from '../features/users/UserEdit';
import { ContentList } from '../features/content/ContentList';
import { ContentCreate } from '../features/content/ContentCreate';
import { ContentDelete } from '../features/content/ContentDelete';
import { ContentEdit } from '../features/content/ContentEdit';
import { ActivityList } from '../features/activities/ActivityList';
import { ActivityCreate } from '../features/activities/ActivityCreate';
import { ActivityDelete } from '../features/activities/ActivityDelete';
import { ActivityEdit } from '../features/activities/ActivityEdit';
import { Login } from '../features/auth/Login';
import { PasswordResetEmail } from '../features/auth/PasswordResetEmail';
import { PasswordReset } from '../features/auth/PasswordReset';
import { Register } from '../features/auth/Register';
import { Logout } from '../features/auth/Logout';
import PrivateRoute from '../features/auth/PrivateRoute';
import { CheckAuth } from '../features/auth/CheckAuth';

function App() {
  return (
    <div className="App">
      <CheckAuth>
        <HistoryRouter history={history}>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/users" element={<UserList />} />
            <Route element={<PrivateRoute />}>
              <Route path="/users/new" element={<UserCreate />} />
              <Route path="/users/delete/:id" element={<UserDelete />} />
              <Route path="/users/edit/:id" element={<UserEdit />} />
            </Route>

            <Route path="/content" element={<ContentList />} />
            <Route element={<PrivateRoute />}>
              <Route path="/content/new" element={<ContentCreate />} />
              <Route path="/content/delete/:id" element={<ContentDelete />} />
              <Route path="/content/edit/:id" element={<ContentEdit />} />
            </Route>

            <Route path="/activities" element={<ActivityList />} />
            <Route element={<PrivateRoute />}>
              <Route path="/activities/new" element={<ActivityCreate />} />
              <Route
                path="/activities/delete/:id"
                element={<ActivityDelete />}
              />
              <Route path="/activities/edit/:id" element={<ActivityEdit />} />
            </Route>

            <Route path="/auth/login" element={<Login />} />
            <Route
              path="/auth/password-reset-email"
              element={<PasswordResetEmail />}
            />
            <Route path="/auth/password-reset" element={<PasswordReset />} />
            <Route path="/auth/register" element={<Register />} />
            <Route path="/auth/logout" element={<Logout />} />
          </Routes>
        </HistoryRouter>
      </CheckAuth>
    </div>
  );
}

export default App;
