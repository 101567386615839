import { history } from '../history/history';

export const onClickShiftCtrl = (event, url = '/') => {
  if (event?.ctrlKey || event?.metaKey) {
    window.open(url, '_blank');
  } else if (event?.shiftKey) {
    window.open(url, '_blank', 'noopener,noreferrer');
  } else {
    // window.location.href = url; // performs a full-page reload
    history.push(url);
  }
};
