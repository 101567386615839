import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { clearAuth } from './authSlice';
import { history } from '../history/history';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const cookieName = 'access_token'; // Substitua pelo nome do seu cookie
  const cookieValue = Cookies.get(cookieName);
  // if (!cookieValue || !userId) {
  //   return <Navigate to="/auth/login" />;
  // }

  useEffect(() => {
    if (!cookieValue) {
      dispatch(clearAuth());
      history.replace(`/auth/login?redirect=${window.location.pathname}`); // Redireciona para a página de login
    }
  }, [dispatch, history]);

  return <Outlet />;
};

export default PrivateRoute;
