import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/studycontrol';
import { history } from '../history/history';
import _ from 'lodash';
import { DateTime } from 'luxon';

// side effects
export const fetchActivities = createAsyncThunk(
  'activities/fetchActivities',
  async () => {
    const response = await api.get('/activities');
    return response.body;
  }
);

export const createActivity = createAsyncThunk(
  'activities/createActivity',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post('/activities').send(formData);
      return response.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editActivity = createAsyncThunk(
  'activities/editActivity',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api
        .put(`/activities/${formData.id}`)
        .send(formData);
      return response.body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchActivity = createAsyncThunk(
  'activities/fetchActivity',
  async (id) => {
    const response = await api.get(`/activities/${id}`);
    return response.body;
  }
);

export const deleteActivity = createAsyncThunk(
  'activities/deleteActivity',
  async (id) => {
    await api.delete(`/activities/${id}`);
    return id;
  }
);

export const fetchActivitiesByUserContent = createAsyncThunk(
  'activities/fetchActivitiesByUserContent',
  async (user, { getState }) => {
    let response;
    let activities = [];

    let params = '';
    const { withTeacher, withoutTeacher } = getState().activities?.uiFilters;
    if (withTeacher) params += `&isClass=true`;
    if (withoutTeacher) params += `&isClass=false`;

    response = await api.get(
      `/activitiesByUserContent?user=${user.id}${params}`
    );
    activities = { ...activities, [user.id]: response.body };

    return activities;
  }
);

export const fetchActivitiesByUserDay = createAsyncThunk(
  'activities/fetchActivitiesByUserDay',
  async (user, { getState }) => {
    let response,
      activities = [];

    // extract string '-03:00' from current browser offset
    let offset = DateTime.local().toISO().slice(-6);

    let params = '';
    const { withTeacher, withoutTeacher } = getState().activities?.uiFilters;
    if (withTeacher) params += `&isClass=true`;
    if (withoutTeacher) params += `&isClass=false`;

    response = await api.get(
      `/activitiesByUserDay?user=${user.id}&tz=${offset}${params}`
    );
    activities = { ...activities, [user.id]: response.body };

    return activities;
  }
);

// Export reusable selector here
export const selectActivityById = (id) => (state) =>
  state?.activities?.byId?.[id] || {};

// MAIN FUNCTION

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState: {
    byId: {},
    byContent: {},
    byDay: {},
    showSuccess: false,
    inProgress: {},
    uiFilters: {
      byGroup: 'content',
      isClass: false,
    },
  },
  reducers: {
    activityInProgress: (state, action) => {
      state.inProgress = action.payload;
    },
    setUiFilters: (state, action) => {
      Object.assign(state.uiFilters, action.payload);
    },
    setShowSuccess: (state, action) => {
      state.showSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.byId = _.mapKeys(action.payload, 'id');
      })
      .addCase(createActivity.fulfilled, (state, action) => {
        history.push(`/activities/edit/${action.payload.id}`);
        state.byId = { ...state.byId, [action.payload.id]: action.payload };
        state.showSuccess = true;
      })
      .addCase(fetchActivity.fulfilled, (state, action) => {
        state.byId = { ...state.byId, [action.payload.id]: action.payload };
      })
      .addCase(fetchActivity.rejected, (state, action) => {
        console.log();
      })
      .addCase(deleteActivity.fulfilled, (state, action) => {
        history.push('/activities');
        state.byId = _.omit(state.byId, action.payload);
      })
      .addCase(editActivity.fulfilled, (state, action) => {
        state.byId = { ...state.byId, [action.payload.id]: action.payload };
        state.showSuccess = true;
      })
      .addCase(fetchActivitiesByUserContent.fulfilled, (state, action) => {
        state.byContent = action.payload;
      })
      .addCase(fetchActivitiesByUserDay.fulfilled, (state, action) => {
        state.byDay = action.payload;
      });
  },
});

export const {
  activityInProgress,
  extraReducers,
  setUiFilters,
  setShowSuccess,
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
