import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  activityInProgress,
  fetchActivities,
  fetchActivitiesByUserContent,
  fetchActivitiesByUserDay,
} from './activitySlice';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardContent,
  Typography,
  Collapse,
  CardHeader,
  Divider,
  CardActionArea,
  Chip,
  Alert,
  Box,
  Fab,
} from '@mui/material';
import {
  AccountCircleOutlined,
  ArticleOutlined,
  DateRangeOutlined,
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowDown,
  ModeEditOutlined,
  QueueMusicOutlined,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { Add as AddIcon } from '@mui/icons-material';
import { DateTime, Duration } from 'luxon';
import { fetchUsers, selectUser } from '../users/userSlice';
import './activity.scss';
import { history } from '../history/history';
import { UserSelect } from '../users/UserSelect';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ActivityFilters } from './ActivityFilters';
import { useSearchParams } from 'react-router-dom';
import { Botton } from '../../app/Botton';
import { Header } from '../../app/Header';
import { onClickShiftCtrl } from '../history/onClickShiftCtrl';

// AUXILIAR LOCAL FUNCTIONS
const getActivityContent = (activity, byGroup = 'content') => {
  const start = DateTime.fromISO(activity?.startTime?.value, {
    locale: 'pt-BR',
  });
  const stop = DateTime.fromISO(activity.stopTime?.value);
  const duration = Duration.fromMillis(activity.duration);

  let title = '',
    subtitle = '';
  // out += start.toLocaleString(DateTime.DATE_SHORT) + ' ';
  if (byGroup === 'content') {
    title += capitalize(start.toFormat('ccc').replace('.', '')) + ' ';
    title += start.toFormat('dd/MM/yy') + ' ';
  }
  if (byGroup === 'day') {
    title += activity?.content?.title + ' ';
  }
  subtitle += ' Duração ' + duration.toFormat("h'h'mm");
  let endText =
    !stop || !stop.isValid ? '(em andamento)' : stop.toFormat("HH'h'mm");
  subtitle += ' | ' + start.toFormat("HH'h'mm") + '-' + endText;

  const description = activity.description;

  return { title, subtitle, description };
};

const capitalize = (str) => {
  return str.charAt(0).toLocaleUpperCase() + str.slice(1);
};

const getDayTitle = (groupedDay) => {
  const start = DateTime.fromISO(groupedDay.day, {
    locale: 'pt-BR',
  });
  const duration = Duration.fromMillis(groupedDay.duration);

  let title = '';
  let subtitle = '';
  title += capitalize(start.toFormat('ccc').replace('.', '')) + ' ';
  title += start.toFormat('dd/MM/yy') + ' ';

  subtitle += ' Duração ' + duration.toFormat("h'h'mm");
  subtitle += ' | ' + groupedDay.activities.length + ' atividades';

  return { title, subtitle };
};

// MAIN REACT FUNCTION

export const ActivityList = () => {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState({});
  let [searchParams] = useSearchParams();

  /** USER Things */

  let userIdAuth = useSelector((state) => state?.auth?.userId);
  const [userId, setUserId] = useState(userIdAuth);

  const { user: selectedUserId } = useSelector(
    (state) => state?.users?.uiFilters
  );

  // quando usuário copia e cola endereço que tenha ?user=...
  useEffect(() => {
    if (searchParams.get('user')) {
      setUserId(searchParams.get('user'));
      dispatch(selectUser(searchParams.get('user')));
    }
  }, []);

  // pega o usuário logado quando não houver outro selecionado
  useEffect(() => {
    if (!searchParams.get('user') && !selectedUserId) {
      dispatch(selectUser(userIdAuth));
    }
  }, [userIdAuth]);

  useEffect(() => {
    setUserId(selectedUserId);
  }, [selectedUserId]);

  // quando muda o usuário, ajusta o endereço
  useEffect(() => {
    if (userId) {
      history.push({ search: `user=${userId}` });
    }
  }, [dispatch, userId, userIdAuth]);

  //read users
  // confirmar se no componente UserSelect já está buscando a lista de usuários
  useEffect(() => {
    dispatch(fetchUsers());
  }, []);
  const users = useSelector((state) => state?.users?.byId);

  /** Other stuff */

  const handleExpandClick = (key) => {
    setExpanded({ ...expanded, [key]: !expanded[key] });
  };

  const { byGroup, withTeacher, withoutTeacher } = useSelector(
    (state) => state?.activities?.uiFilters
  );

  const methods = useForm({
    mode: 'onBlur',
  });

  //fetch conditionally
  useEffect(() => {
    switch (byGroup) {
      case 'content':
        dispatch(fetchActivitiesByUserContent(users[userId]));
        break;
      case 'day':
        dispatch(fetchActivitiesByUserDay(users[userId]));
        break;
      case 'activity':
        dispatch(fetchActivities());
        break;
      default:
        break;
    }
  }, [byGroup, withTeacher, withoutTeacher, userId, users]);

  const activitiesByContent = useSelector(
    (state) => state.activities?.byContent
  );

  const activitiesByDay = useSelector((state) => state.activities?.byDay);

  const handleNewActivity = (activity = {}) => {
    const newActivity = {
      user: activity?.user || null,
      content: activity?.content || null,
    };
    dispatch(activityInProgress(newActivity));
  };

  const renderNoActivity = () => {
    if (userId)
      return (
        <Alert severity="info" sx={{ mt: '2em' }}>
          Nenhuma atividade encontrada
        </Alert>
      );
  };

  const renderNewActivity = (activity, similar = false) => {
    const content = similar
      ? 'Criar atividade similar...'
      : 'Criar atividade...';
    return (
      <ListItem>
        <Link to={`/activities/new`}>
          <ListItemSecondaryAction>
            <AddIcon />
          </ListItemSecondaryAction>
        </Link>
        <ListItemText>
          <Link
            to={`/activities/new`}
            onClick={() => {
              handleNewActivity(activity);
            }}
          >
            {content}
          </Link>
        </ListItemText>
      </ListItem>
    );
  };

  const renderSummary = (obj) => {
    const results = Object.values(obj);

    let count =
      results.length > 1
        ? `${results.length} resultados`
        : `${results.length} resultado`;
    let totalDuration = results.reduce(
      (acc, current) => acc + current.duration,
      0
    );
    totalDuration = Duration.fromMillis(totalDuration).toFormat("hh'h'mm");

    return (
      results.length > 0 && (
        <Alert severity="info">
          {count} - {totalDuration}
        </Alert>
      )
    );
  };

  const renderByGroup = (activitiesGrouped) => {
    if (byGroup === 'content') activitiesGrouped = activitiesByContent;
    if (byGroup === 'day') activitiesGrouped = activitiesByDay;

    if (!activitiesGrouped) return renderNoActivity();
    if (Object.keys(activitiesGrouped).length === 0) return renderNoActivity();
    if (!userId) return renderNoActivity();

    return Object.entries(activitiesGrouped).map(([userId, nestedObj]) => {
      return (
        <React.Fragment key={uuidv4()}>
          {renderSummary(nestedObj)}
          {renderCardHeaders(nestedObj, users[userId].id)}
        </React.Fragment>
      );
    });
  };

  const renderCardHeaders = (nestedByUser, userId) => {
    if (nestedByUser.length === 0) {
      return renderNoActivity();
    }

    return nestedByUser.map((nestedObject, index, array) => {
      let uid = null;
      let title = '';
      let subheader = '';
      let avatar;
      const font = { fontSize: 'large' };

      if (byGroup === 'content') {
        uid = `${nestedObject.content.id}`;
        title = nestedObject.content.title;
        subheader =
          Duration.fromMillis(nestedObject.duration).toFormat("h'h'mm") +
          ' | ' +
          nestedObject.activities.length +
          ' atividades';
        avatar = nestedObject.content.isMusic ? (
          <QueueMusicOutlined {...font} />
        ) : (
          <ArticleOutlined {...font} />
        );
      }

      if (byGroup === 'day') {
        uid = `${nestedObject.day}`;
        title = `${array.length - index}. ${getDayTitle(nestedObject).title}`;
        subheader = getDayTitle(nestedObject).subtitle;
        avatar = <DateRangeOutlined {...font} />;
      }

      return (
        <React.Fragment key={uuidv4()}>
          <Card key={uid} sx={{ margin: '1.5em 0' }}>
            <CardActionArea onClick={() => handleExpandClick(uid)}>
              <CardHeader
                avatar={avatar}
                action={
                  <ExpandMore
                    expand={expanded[uid]}
                    onClick={() => handleExpandClick(uid)}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                }
                title={
                  <Typography sx={{ fontSize: '1.5em' }}>{title}</Typography>
                }
                subheader={
                  <Typography
                    color="text.secondary"
                    component="p"
                    className="content-secondary"
                  >
                    {subheader}
                  </Typography>
                }
              />
            </CardActionArea>
            <Collapse in={expanded[uid]} timeout="auto" unmountOnExit>
              {renderCardItems(nestedObject?.activities, byGroup)}
            </Collapse>
          </Card>
        </React.Fragment>
      );
    });
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <KeyboardArrowDown {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const renderCardItems = (activities, byGroup = 'content') => {
    // if (activities?.length === 0) return renderNewActivity();

    return (
      <>
        {activities.map((activity, index, array) => {
          const count =
            byGroup === 'content' ? array.length - index : index + 1;

          const isClassChip = activity.isClass ? (
            <CardContent>
              <Chip
                label="com professor"
                size="small"
                variant="outlined"
                // color="hsl(0,50%, 50%)"
                sx={{
                  mt: '-25px',
                  ml: '5px',
                }}
                avatar={<AccountCircleOutlined />}
              />
            </CardContent>
          ) : (
            ''
          );

          return (
            <React.Fragment key={uuidv4()}>
              <Divider />
              <div>
                <CardActionArea
                  onClick={(event) =>
                    onClickShiftCtrl(event, `/activities/edit/${activity.id}`)
                  }
                  sx={{ padding: '0.5em 0', backgroundColor: '#f1f1f1' }}
                >
                  <CardHeader
                    avatar={
                      <Typography sx={{ fontSize: 30 }}>{count}</Typography>
                    }
                    title={
                      <Typography
                        color="text.primary"
                        component="span"
                        sx={{ fontSize: '1.2em' }}
                      >
                        {getActivityContent(activity, byGroup).title}
                      </Typography>
                    }
                    subheader={
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        {getActivityContent(activity, byGroup).subtitle}
                      </Typography>
                    }
                    action={
                      <ModeEditOutlined
                        sx={{ color: 'gray' }}
                        fontSize="medium"
                      />
                    }
                  />

                  {!!activity.description && activity.description !== '' && (
                    <CardContent sx={{ margin: '0.7em' }}>
                      <Typography
                        variant="body2"
                        component="pre"
                        sx={{ whiteSpace: 'pre-wrap' }}
                      >
                        {getActivityContent(activity, byGroup).description}
                      </Typography>
                      {/* {isClassChip} */}
                    </CardContent>
                  )}
                  {isClassChip}
                </CardActionArea>
              </div>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <div className="AppMaxWidth activity">
      <FormProvider {...methods}>
        <Header title="Atividades" />

        <Box className="user-select" sx={{ mt: '1em' }}>
          <UserSelect />
        </Box>

        {userId && (
          <Box sx={{ margin: '2em 0 2em 0' }}>
            <ActivityFilters />
          </Box>
        )}

        {renderByGroup()}
        <br />
        <br />

        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: 'fixed',
            bottom: '72px',
            right: '16px',
          }}
          onClick={(event) => {
            onClickShiftCtrl(event, '/activities/new');
            handleNewActivity();
          }}
        >
          <AddIcon />
        </Fab>
      </FormProvider>
      <Botton />
    </div>
  );
};
