import prefix from 'superagent-prefix';
const request = require('superagent-use')(require('superagent'));

// Middleware to prefix the URL
request.use(prefix(process.env.REACT_APP_API_URI));

// Middleware to include credentials (cookies)
request.use((req) => {
  req.withCredentials(); // Ensure cookies are included in the request
  return req;
});

export default request;
