import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import { Header } from '../../app/Header';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearErrors as clearServerErrors,
  requestResetPasswordToken,
} from './authSlice';
import { useSchemaResolver } from '../schemas/useSchemaResolver';

import parseErr from '../errors/parse';
import { DebugData } from '../errors/DebugData';
import { useEffect, useState } from 'react';
import { DebugSwitch } from '../errors/DebugSwitch';

export const PasswordResetEmail = () => {
  const variant = 'standard';
  const [debug, setDebug] = useState(false);
  const dispatch = useDispatch();
  const serverErrors = useSelector((state) => state?.auth?.errors);
  const resetEmailSent = useSelector((state) => state?.auth.resetEmailSent);
  const resolver = useSchemaResolver(
    'auth/password-reset-email-input.schema.json'
  );

  const methods = useForm({
    defaultValues: {},
    resolver: resolver,
    mode: 'onBlur',
    criteriaMode: 'all',
  });
  const { control, handleSubmit, formState, reset, values } = methods;

  const {
    isDirty,
    isSubmitted,
    isSubmitSuccessful,
    isSubmitting,
    isValid, // SEM ESTE NÃO RESETA isDirty após envio do formulário!!!!! Não sei por que
    errors,
  } = formState;

  const onSubmit = (data) => {
    dispatch(requestResetPasswordToken(data));
  };

  useEffect(() => {
    dispatch(clearServerErrors());
  }, []);

  useEffect(() => {
    if (isSubmitted && isSubmitSuccessful && serverErrors?.length > 0) {
      reset(values, { keepValues: true });
    }
  }, [isSubmitted, isSubmitSuccessful, serverErrors?.length]);

  useEffect(() => {
    if (isDirty && serverErrors?.length > 0) {
      dispatch(clearServerErrors());
    }
  }, [isDirty]);

  const sendingBt = () => {
    if (isSubmitting) return true;
    if (Object.keys(errors).length > 0) return false;
    if (serverErrors?.length > 0) return false;
    if (isSubmitted && isSubmitSuccessful && serverErrors?.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="AppMaxWidth">
        <FormProvider {...methods}>
          <Header title="Recuperação de Senha" showAuthUi={false} />
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2, // Espaçamento entre as linhas
              width: '100%', // Ocupa toda a largura disponível
            }}
          >
            {!resetEmailSent ? (
              <>
                {serverErrors?.length > 0 && (
                  <Alert severity="error">
                    {parseErr(serverErrors)?.email?.message}
                  </Alert>
                )}

                <Typography>
                  Para receber um link com a autorização para recuperação de sua
                  senha, digite o email:
                </Typography>

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Digite o email"
                      name={field.name}
                      type="email"
                      value={field.value || ''}
                      variant={variant}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          console.log('acessado onKeyDown/Enter');
                          handleSubmit(onSubmit)();
                        }
                      }}
                      error={
                        !!errors[field?.name] ||
                        !!parseErr(serverErrors)[field?.name]
                      }
                      helperText={
                        errors[field.name]?.message ||
                        parseErr(serverErrors)[field?.name]?.message
                      }
                    />
                  )}
                />

                {!sendingBt(formState, serverErrors) && (
                  <Button
                    variant="contained"
                    onClick={() => handleSubmit(onSubmit)()}
                  >
                    Enviar
                  </Button>
                )}
                {sendingBt(formState, serverErrors) && (
                  <Button variant="contained" disabled>
                    Enviando...
                  </Button>
                )}
                <DebugSwitch debug={debug} setDebug={setDebug} />
                <DebugData debug={debug} />
              </>
            ) : (
              <Box>
                <Alert severity="success">Email enviado.</Alert>

                <Box>&nbsp;</Box>

                <Box>
                  Abra seu email em uma nova aba, e clique no link para concluir
                  a restauração de sua senha.
                </Box>
              </Box>
            )}
          </Box>
        </FormProvider>
      </div>
    </>
  );
};
