import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchActivity, deleteActivity } from './activitySlice';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const ActivityDelete = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActivity(id));
  }, [id, dispatch]);

  const handleClose = () => {
    navigate(-1);
  };
  const handleConfirm = (id) => {
    dispatch(deleteActivity(id));
  };

  const activity = useSelector((state) => state?.activities?.byId[id]);

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Apagar atividade'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você tem certeza que deseja apagar a atividade {activity?.description}{' '}
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Não</Button>
        <Button onClick={() => handleConfirm(id)} autoFocus>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};
