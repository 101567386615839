import { useDispatch } from 'react-redux';
import { clearAuth } from './authSlice';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { history } from '../history/history';

export const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    Cookies.remove('access_token');
    dispatch(clearAuth());
    history.push('/');
  }, [dispatch]);
  return <>Logout</>;
};
