import React, { useEffect, useState } from 'react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';

const hasTime = (text) => {
  return typeof text === 'string' && text.search(/\d{2}:\d{2}:\d{2}/) >= 0;
};

export const TimeField = ({ label, name }) => {
  const variant = 'standard';

  const { setValue, control, unregister } = useFormContext();
  const isoDateTime = useWatch({ control, name });

  const [shortTime, setShortTime] = useState();
  const [inError, setInError] = useState(false);

  useEffect(() => {
    if (hasTime(isoDateTime)) {
      setInError(false);
      setShortTime(toShort(isoDateTime));
    }
    if (!isoDateTime) {
      setShortTime('');
    }
    // if (name === 'stopTime.value' && !hasTime(isoDateTime)) unregister(name);
  }, [isoDateTime]);

  const isoStartTime = useWatch({ control, name: 'startTime.value' });

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <TextField
              name={field.name}
              label={label}
              className="time-field"
              variant={variant}
              sx={{ width: '70px' }}
              value={shortTime || ''}
              error={inError}
              helperText={inError ? 'hh:mm:ss' : ''}
              onChange={(event) => setShortTime(event.target.value)}
              onBlur={(event) => {
                let localeForTime = event.target.value;

                if (DateTime.fromISO(localeForTime).isValid) {
                  setInError(false);
                } else if (
                  name === 'stopTime.value' &&
                  event.target.value === ''
                ) {
                  setInError(false);
                  unregister(name);
                } else {
                  setInError(true);
                }

                let iso = toLong(localeForTime, isoDateTime);

                if (name === 'stopTime.value') {
                  localeForTime = shortTime;
                  iso = toLong(localeForTime, isoStartTime || '');
                }

                event.target.value = iso;
                field.onChange(event);
              }}
              onFocus={(event) => event.target.select()}
            />
          );
        }}
      />
    </>
  );
};

const toLong = (text, ISOForDate = undefined) => {
  const regexp = /^(\d{1,2}):?(\d{1,2}):?(\d{1,2})?\.?(\d{1,3})?/gm;
  const matches = typeof text === 'string' ? text.split(regexp) : [];
  const [, hour, minute, second, millisecond] = matches;

  const hasTime = matches.length < 3 ? false : true;

  // //quando existir isoDateTime com dia diferente
  let hasDate;
  if (ISOForDate === undefined) hasDate = false;
  if (
    typeof ISOForDate === 'string' &&
    ISOForDate.search(/\d{4}-\d{2}-\d{2}/) >= 0
  )
    hasDate = true;

  const isoDateTime = DateTime.fromISO(ISOForDate);

  if (hasTime && !hasDate) {
    return DateTime.fromObject({
      hour,
      minute,
      second,
      millisecond,
    }).toISOTime();
  }

  if (hasTime && hasDate) {
    const { year, month, day } = isoDateTime.toObject();
    return DateTime.fromObject({
      year,
      month,
      day,
      hour,
      minute,
      second,
      millisecond,
    }).toISO();
  }

  if (!hasTime && hasDate) {
    const { year, month, day } = isoDateTime.toObject();
    return DateTime.fromObject({
      year,
      month,
      day,
    }).toISODate();
  }

  if (!hasTime && !hasDate) return undefined;
};

const toShort = (ISOTime) => {
  const date = DateTime.fromISO(ISOTime);
  if (!date.isValid) return;
  const { second, millisecond } = date.toObject();
  let format = 'HH:mm';
  format = second ? 'HH:mm:ss' : format;
  format = millisecond ? 'HH:mm:ss.SSS' : format;
  return date.toFormat(format);
};
