import React, { useEffect, useState } from 'react';
import {
  useWatch,
  Controller,
  useForm,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { TextField, Tooltip } from '@mui/material';
import { DateTime, Duration } from 'luxon';

const checkDuration = (start, stop) => {
  if (!start.isValid) return null;
  if (!stop.isValid) return null;
  try {
    let duration = stop.diff(start).toMillis();
    if (duration < 0) return undefined;
    return duration;
  } catch (err) {
    // console.log(err);
  }
};

export const DurationField = ({ name, label, startName, stopName }) => {
  const variant = 'standard';
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const start = useWatch({ control: control, name: startName });
  const stop = useWatch({ control: control, name: stopName });
  const [duration, setDuration] = useState();

  useEffect(() => {
    // initiate variables
    const now = DateTime.now();
    const startTime = DateTime.fromISO(start);
    const stopTime = DateTime.fromISO(stop);

    // set duration cases
    const duration = checkDuration(startTime, stopTime);
    if (duration) setDuration(duration);
    if (!startTime.isValid && !stopTime.isValid) setDuration(null); // to empty
    if (!stopTime.isValid && startTime >= now) setDuration(null); // to empty
    if (stopTime < startTime) setDuration(-1);

    // Timer / Stopwatch
    let interval;
    if (startTime.day === now.day && startTime < now && !stopTime.isValid) {
      interval = setInterval(handleElapsedTime, 100);
    }
    if (stopTime.isValid) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [start, stop]);

  useEffect(() => {
    setValue(name, duration);
  }, [duration]);

  const handleElapsedTime = () => {
    const startTime = DateTime.fromISO(start);
    const elapsedMillis = DateTime.now().diff(startTime).toMillis();
    setDuration(elapsedMillis);
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const inError = duration < 0 || !!errors[field.name];
          const text = inError ? '0 ou mais' : '';
          return (
            <Tooltip title={errors[field.name]?.message}>
              <TextField
                name={name}
                label={label}
                value={Duration.fromMillis(duration).toFormat('hh:mm:ss') || ''}
                onChange={(event) => {
                  // console.log(duration);
                  console.log('Duration OnChange');
                  event.target.value = duration;
                  console.log(event);
                  field.onChange(event);
                }}
                variant={variant}
                error={inError}
                helperText={text}
                sx={{ width: '80px' }}
                disabled
              />
            </Tooltip>
          );
        }}
      />
    </>
  );
};
