import React, { useEffect } from 'react';
import { ActivityForm } from './ActivityForm';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createActivity } from './activitySlice';

import { useSchemaResolver } from '../schemas/useSchemaResolver';
import { Header } from '../../app/Header';

export const ActivityCreate = () => {
  const dispatch = useDispatch();

  const onSubmit = async (formData) => dispatch(createActivity(formData));

  const activity = useSelector((state) => state?.activities?.inProgress || {});

  const resolver = useSchemaResolver('activity.schema.json');

  const methods = useForm({
    defaultValues: {
      user: '',
      content: '',
      duration: null,
    },
    values: activity,
    resolver: resolver,
    mode: 'onChange',
    // reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  return (
    <div className="AppMaxWidth">
      <Header title="Nova Atividade" showBackIcon={true} />
      <ActivityForm methods={methods} onSubmit={onSubmit} activity={activity} />
    </div>
  );
};
