import React, { useEffect } from 'react';
import { ActivityForm } from './ActivityForm';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { editActivity, fetchActivity } from './activitySlice';
import { useParams } from 'react-router-dom';
import { useSchemaResolver } from '../schemas/useSchemaResolver';
import { Header } from '../../app/Header';

export const ActivityEdit = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const onSubmit = async (formData) => dispatch(editActivity(formData));

  useEffect(() => {
    dispatch(fetchActivity(id));
  }, [id, dispatch]);

  const activity = useSelector((state) => state?.activities?.byId?.[id] || {});

  const resolver = useSchemaResolver('activity.schema.json');

  const methods = useForm({
    defaultValues: activity,
    values: activity,
    resolver: resolver,
    mode: 'onBlur',
  });

  return (
    <div className="AppMaxWidth">
      <Header title="Editar Atividade" showBackIcon={true} />
      <ActivityForm
        methods={methods}
        onSubmit={onSubmit}
        inEditMode={true}
        activity={activity}
      />
    </div>
  );
};
