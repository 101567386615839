import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { UserForm } from './UserForm';
import { useDispatch, useSelector } from 'react-redux';
import { editUser, fetchUser } from './userSlice';
import { useSchemaResolver } from '../schemas/useSchemaResolver';
import { Header } from '../../app/Header';

export const UserEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // fetch user
  useEffect(() => {
    dispatch(fetchUser(id));
  }, [id, dispatch]);

  const initialValues = useSelector((state) => state?.users?.byId[id]) || {};

  const resolver = useSchemaResolver('user.schema.json');

  const methods = useForm({
    defaultValues: initialValues,
    values: initialValues,
    resolver: resolver,
    mode: 'onBlur',
  });

  const handleSubmit = async (formData) => await dispatch(editUser(formData));

  return (
    <div className="AppMaxWidth">
      <FormProvider {...methods}>
        <Header title="Editar pessoa" showBackIcon={true} />
        <UserForm onSubmit={handleSubmit} inEditMode={true} />
      </FormProvider>
    </div>
  );
};
