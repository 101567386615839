import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { TextField, Autocomplete } from "@mui/material";

const options = [
  { label: "Masculino", value: "male" },
  { label: "Femenino", value: "female" },
];

export const UserGender = ({ variant = "standard" }) => {
  const { control } = useFormContext();
  const genderId = useWatch({ control, name: "gender" });
  const gender = toObj(genderId, options);

  return (
    <Controller
      name="gender"
      control={control}
      render={({ field }) => (
        <Autocomplete
          disablePortal
          autoSelect
          autoHighlight
          options={options}
          value={gender || null}
          renderInput={(params) => {
            return <TextField {...params} label="Gênero" variant={variant} />;
          }}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(event, selected) => {
            field.onChange(selected?.value);
          }}
        />
      )}
    />
  );
};

const toObj = (id, options) => {
  if (!Array.isArray(options)) return;
  const result = options.filter((option) => option.value === id);
  if (!result && result.length === 0) return;
  return result[0];
};
