import React, { useState, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';
import IMask from 'imask';

const hasDate = (text) => {
  return typeof text === 'string' && text.search(/\d{4}-\d{2}-\d{2}/) >= 0;
};

export const DateField = ({ name, label, focused }) => {
  const variant = 'standard';

  const { control } = useFormContext();

  const isoDateTime = useWatch({ control, name });

  const [localeDate, setLocaleDate] = useState('');

  useEffect(() => {
    if (hasDate(isoDateTime)) {
      setLocaleDate(toShort(isoDateTime));
    }
    if (isoDateTime === undefined) setLocaleDate('');
  }, [isoDateTime]);

  const handleChange = (event) => {
    const { value } = event.target;
    const localeOpts = { locale: navigator.language };
    const expandedFormat = DateTime.expandFormat('D', localeOpts)
      .replace(/y+/, 'y{2,4}')
      .replace(/M+/, 'MM')
      .replace(/d+/, 'dd');
    const masked = IMask.createMask({
      mask: Date,
      pattern: expandedFormat,
      blocks: {
        y: {
          mask: IMask.MaskedRange,
          from: 10,
          to: 9999,
          maxLength: 4,
        },
        dd: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
      },
      // define date -> str convertion
      format: function (date) {
        return DateTime.fromJSDate(date).toFormat(expandedFormat);
      },

      // define str -> date convertion
      parse: function (str) {
        return DateTime.fromFormat(str, expandedFormat).toJSDate();
      },

      // autofix: false, // defaults to `false`, see details

      // lazy: false,

      overwrite: true, // defaults to `false`,

      // skipInvalid: false,
    });
    masked.resolve(value);

    setLocaleDate(masked.value);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <TextField
            name={field.name}
            label={'Data'}
            onFocus={(event) => event.target.select()}
            focused={focused}
            autoFocus={focused}
            variant={variant}
            sx={{ width: '90px' }}
            value={localeDate || ''}
            onChange={(event) => handleChange(event, field)}
            onBlur={(event) => {
              const localeForDate =
                event.target.value === ''
                  ? ''
                  : autocompleteDate(event.target.value, dateFormat);
              const long = toLong(localeForDate, isoDateTime);
              field.onChange(long);
            }}
          />
        );
      }}
    />
  );
};

const toShort = (dateTimeISO) => {
  const date = DateTime.fromISO(dateTimeISO);
  if (!date.isValid) return;
  return date.toLocaleString(DateTime.DATE_SHORT);
};

const toLong = (localeForDate, ISOForTime = undefined, name = undefined) => {
  let hasDate;
  if (!localeForDate) hasDate = false;
  const short = DateTime.fromFormat(localeForDate || '', dateFormat);
  if (short.isValid) hasDate = true;
  if (localeForDate === '') hasDate = false;
  const { year, month, day } = short.toObject();

  let hasTime;
  if (ISOForTime === undefined) hasTime = false;

  if (
    typeof ISOForTime === 'string' &&
    ISOForTime.search(/\d{2}:\d{2}:\d{2}/) >= 0
  )
    hasTime = true;

  if (!hasTime && hasDate) {
    return DateTime.fromObject({ year, month, day }).toISODate();
  }

  if (hasTime && hasDate) {
    const { hour, minute, second, millisecond } =
      DateTime.fromISO(ISOForTime).toObject();
    return DateTime.fromObject({
      year,
      month,
      day,
      hour,
      minute,
      second,
      millisecond,
    }).toISO();
  }

  if (hasTime && !hasDate) {
    const { hour, minute, second, millisecond } =
      DateTime.fromISO(ISOForTime).toObject();
    return DateTime.fromObject({
      hour,
      minute,
      second,
      millisecond,
    }).toISOTime();
  }
};

const dateFormat = DateTime.parseFormatForOpts({
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}).replace('yyyyy', 'yyyy');

const autocompleteDate = (text, toDateFormatToken) => {
  if (text === '') return;
  const numbers = text.split('/');

  const tokens = DateTime.parseFormatForOpts({
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
    .replace('yyyyy', 'yyyy')
    .split('/');

  for (let i = 0; i < tokens.length; i++) {
    numbers[i] = numbers[i] || DateTime.now().toFormat(tokens[i]);

    let twoDigitYear = parseInt(numbers[i]);
    if (
      tokens[i] === 'yyyy' &&
      numbers[i].length === 2 &&
      !isNaN(twoDigitYear)
    ) {
      numbers[i] =
        twoDigitYear < 60 ? `20${twoDigitYear}` : `19${twoDigitYear}`;
    }
  }

  const date = DateTime.fromFormat(numbers.join('/'), tokens.join('/'));
  if (!date.isValid) return;
  return date.toFormat(toDateFormatToken);
};
