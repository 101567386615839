import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchContent, deleteContent } from './contentSlice';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const ContentDelete = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContent(id));
  }, [id, dispatch]);

  const handleClose = () => {
    navigate(-1);
  };
  const handleConfirm = (id) => {
    dispatch(deleteContent(id));
  };

  const content = useSelector((state) => state?.content?.byId[id]);

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Apagar conteúdo'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você tem certeza que deseja apagar o conteúdo {content?.title} ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Não</Button>
        <Button onClick={() => handleConfirm(id)} autoFocus>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};
