import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UserForm } from './UserForm';
import { useDispatch } from 'react-redux';
import { createUser } from './userSlice';
import { ServerError } from '../errors/ServerError';
import { useSchemaResolver } from '../schemas/useSchemaResolver';
import { Header } from '../../app/Header';

export const UserCreate = () => {
  const resolver = useSchemaResolver('user.schema.json');

  const methods = useForm({
    defaultValues: {},
    resolver: resolver,
    mode: 'onBlur',
  });

  const dispatch = useDispatch();
  const [serverErr, setServerErr] = useState({});

  const handleSubmit = async (formData) => {
    try {
      await dispatch(createUser(formData));
    } catch (e) {
      setServerErr(e);
    }
  };

  return (
    <div className="AppMaxWidth">
      <FormProvider {...methods}>
        <Header title="Nova Pessoa" showBackIcon={true} />
        {serverErr.message && <ServerError serverErr={serverErr} />}
        <UserForm onSubmit={handleSubmit} />
      </FormProvider>
    </div>
  );
};
