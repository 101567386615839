import { ArrowBack } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { history } from '../features/history/history';

export const Header = ({
  title = '',
  showBackIcon = false,
  showAuthUi = true,
}) => {
  const userId = useSelector((state) => state?.auth.userId);
  const BackIcon = (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 'auto' }}
        onClick={() => {
          window.history.back();
        }}
      >
        {showBackIcon ? <ArrowBack /> : <></>}
      </IconButton>
    </Box>
  );

  const authText = !!userId ? 'Sair' : 'Entrar';
  const authUrl = !!userId ? '/auth/logout' : '/auth/login';
  const AuthUi = (
    <Box sx={{ marginLeft: 'auto' }}>
      <Button
        variant="text"
        color="success"
        sx={{ color: 'white' }}
        onClick={() => history.push(authUrl)}
      >
        <Typography>{authText}</Typography>
      </Button>
    </Box>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              {BackIcon}
              <Box
                align="center"
                sx={{
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  textAlign: 'center',
                }}
              >
                <Typography variant="h5" align="center" component="div">
                  {title}
                </Typography>
              </Box>

              {showAuthUi && AuthUi}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      <Box sx={{ mt: '56px' }}>&nbsp;</Box>
    </>
  );
};
