import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import api from '../../api/studycontrol';
import { history } from '../history/history';

// async actions (fetch) with thunk
export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await api.get('/users?sort[name]=1');
  return response.body;
});

export const fetchUser = createAsyncThunk('users/fethUser', async (id) => {
  const response = await api.get(`/users/${id}`);
  return response.body;
});

export const createUser = createAsyncThunk(
  'users/createUser',
  async (formData) => {
    const response = await api.post('/users').send(formData);
    return response.body;
  }
);

export const editUser = createAsyncThunk('users/editUser', async (formData) => {
  const response = await api.put(`/users/${formData.id}`).send(formData);
  return response.body;
});

export const deleteUser = createAsyncThunk('users/deleteUser', async (id) => {
  await api.delete(`/users/${id}`);
  // history.push('/users');
  return id;
});

// slices (reducers, etc)
export const userSlice = createSlice({
  name: 'users',
  initialState: {
    byId: {},
    showSuccess: false,
    uiFilters: {
      user: null,
    },
    errors: [],
  },
  reducers: {
    selectUser: (state, action) => {
      state.uiFilters.user = action.payload;
    },
    setShowSuccess: (state, action) => {
      state.showSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Use `extraReducers` to handle actions that were generated
    // _outside_ of the slice, such as thunks or in other slices
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.byId = _.mapKeys(action.payload, 'id');
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.byId = { ...state.byId, [action.payload.id]: action.payload };
      })
      .addCase(createUser.fulfilled, (state, action) => {
        history.push(`/users/edit/${action.payload.id}`);
        state.byId = {
          ...state.byId,
          [action.payload.id]: action.payload,
        };
        state.showSuccess = true;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.byId = { ...state.byId, [action.payload.id]: action.payload };
        state.showSuccess = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        history.push('/users');
        state.byId = _.omit(state.byId, action.payload);
      });
  },
});

export const { extraReducers, selectUser, setShowSuccess } = userSlice.actions;

export default userSlice.reducer;
