import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createContent } from './contentSlice';
import { ContentForm } from './ContentForm';
import { useSchemaResolver } from '../schemas/useSchemaResolver';
import { Header } from '../../app/Header';

export const ContentCreate = () => {
  const resolver = useSchemaResolver('content.schema.json');
  const methods = useForm({
    defaultValues: {
      isMusic: true,
    },
    resolver: resolver,
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const dispatch = useDispatch();
  const onSubmit = async (formData) => dispatch(createContent(formData));

  return (
    <div className="AppMaxWidth">
      <FormProvider {...methods}>
        <Header title="Novo conteúdo" showBackIcon={true} />
        <ContentForm onSubmit={onSubmit} />
      </FormProvider>
    </div>
  );
};
