import React from 'react';
import { Alert } from '@mui/material';

const ServerError = ({ serverErr }) => {
  console.log('entrei ServerError');
  if (!serverErr.status)
    return (
      <Alert severity="error">Não foi possível conectar ao servidor</Alert>
    );

  return (
    <Alert severity="error">
      <span>API error: </span>
      {serverErr.status && <span> {serverErr.status} </span>}
      {serverErr.message && <span>{serverErr.message}. </span>}
      {serverErr.response.body.errors.length && (
        <ul>
          {serverErr.response.body.errors.map((e, i) => (
            <li key={i}>
              <b>{e.prop}</b>
              {`: ${e.message}`}
            </li>
          ))}
        </ul>
      )}
    </Alert>
  );
};

export { ServerError };
