import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Autocomplete } from '@mui/material';
import { fetchContents } from '../../features/content/contentSlice';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';

export const ContentSelect = ({ variant = 'standard' }) => {
  const { control } = useFormContext();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchContents());
  }, [dispatch]);
  const contents = useSelector((state) => state?.content?.byId);
  const options = Object.entries(contents).map(([id, content]) => ({
    label: content.title,
    value: id,
  }));

  const contentId = useWatch({ control, name: 'content' });
  const content = toObj(contentId, options);

  const { errors } = useFormState({ control });

  return (
    <div>
      <Controller
        name="content"
        control={control}
        render={({ field }) => {
          return (
            <Autocomplete
              disablePortal
              autoSelect
              autoHighlight
              options={options}
              value={content || null}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Conteúdo"
                    variant={variant}
                    error={!!errors[field.name]}
                    helperText={
                      !!errors[field.name]
                        ? 'Deve corresponder a um conteúdo existente'
                        : ''
                    }
                  />
                );
              }}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(event, selected) => {
                field.onChange(selected?.value);
              }}
            />
          );
        }}
      />
    </div>
  );
};

const toObj = (id, options) => {
  if (!Array.isArray(options)) return;
  const result = options.filter((option) => option.value === id);
  if (!result && result.length === 0) return;
  return result[0];
};
