import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { TextField, Box } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import './AdditionalName.scss';

export const AdditionalName = ({ name, variant = 'standard' }) => {
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  // se não não mostra quando vazio
  if (fields.length === 0) append('');

  return (
    <div className="additional-name">
      {fields.map((field, index) => {
        return (
          <Box
            key={index}
            sx={{ display: 'flex', width: '100%' }}
            className="form-line"
          >
            <Box sx={{ flexGrow: 1 }}>
              <Controller
                name={`${name}.${index}`}
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      label={`Nome adicional ${index + 1}`}
                      key={`text-${index}`}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      name={field.name}
                      value={watch(`${name}.${index}`) || ''}
                      inputRef={field.ref}
                      error={!!errors.additionalName}
                      helperText={errors.additionalName?.message}
                      fullWidth
                      variant={variant}
                    />
                  );
                }}
              />
            </Box>
            {/* não exibir quando houver mais de um */}
            {fields.length > 1 && (
              <Box sx={{ m: '0 0 0 1.0rem' }}>
                <RemoveIcon
                  className="additional-name-add"
                  key={`remove-${index}`}
                  onClick={() => remove(index)}
                />
              </Box>
            )}
            {/* adicionar quando não for o último*/}
            {index === fields.length - 1 && (
              <Box sx={{ m: '0 0 0 1.0rem' }}>
                <AddIcon
                  className="additional-name-remove"
                  onClick={() => append('')}
                />
              </Box>
            )}
          </Box>
        );
      })}
    </div>
  );
};
